@import url('https://fonts.googleapis.com/css?family=Sora:100,200,300,regular,500,600,700,800');

.outterdiv {
    background: var(--BG-g1, linear-gradient(283deg, #B048FF -174.56%, rgba(145, 0, 255, 0.00) 109.23%));
    background-size: cover; 
    display: flex;
    max-width: 100%;
    /* height: 350px; */
    padding: 90px 70px;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    overflow: hidden;

}
@media (max-width: 1040px) {
    .outterdiv {
      flex-direction: column;
      padding: 60px 20px;
    }
  } 
.sdivrow  p {
    color: #8F8F8F;
    font-family: Manrope;
    font-size: clamp(14px,2.5vw,18px); 
    font-weight: 600;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding-bottom: 40px;


}

.sdivrow h1 {
    color: #151515;
    font-family: 'Sora', sans-serif;
    font-size: clamp(20px,5.5vw,60px); 
    font-weight: 600;
    line-height: .9; 
    letter-spacing: 0.5px;
    white-space: nowrap;
    padding-bottom: 30px;
}



.sectiondiv {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    
}

.innerdiv div {
    color: #151515;
    font-family: Manrope;
    font-size:clamp(15px,2.5vw,18px); 
    font-weight: 400;
    line-height: 160%; 
    padding-bottom: 47px;
    text-wrap: balance;
}

.innerdiv2 {
    position: absolute;
    top: 0px;
    /* bottom: 90.403px;    */
    width: 361.323px;
    right: 170.534px;
    height: 825.167px;
    z-index: -3;
}

/* .imgsvg {
    width: 100%;
    height: 80px;
    display: block;
} */
