

.header{
    display: flex;
    width: 100%;
    height: 100px;
    padding: 0px 70px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color:#450079;

}

.nav-menu{
    display: flex;
    gap: 59px;
    list-style-type: none;    
   background-color:#450079;
}

.nav-menu li a  {
    color: #A6A6A6;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    letter-spacing: 0.5px;
    text-decoration: none;

}
.nav-menu li.active {
    
    font-family: Manrope;
    font-size: 10px;
   font-style: normal;
   font-weight: 400;
   line-height: 160%; 
   letter-spacing: 0.5px;  
   border-bottom: 3px solid #9200FF; 
   padding-bottom: 6px;
   
 }
  
.hamburger{
    display:none;
}
.sub-menu{
    display :none;
    background-color:#444;
    position :relative;
    top :70.48px;
    left :455px;
    padding :0;
    margin :0;
    overflow:hidden
}
.sub-menu li{
    display :block
}

.nav-dropdown {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.60);
  /* backdrop-filter: blur(28px);       */
  z-index: 3;
  padding: 10px  ;
  list-style-type: none;

}
.nav-dropdown li a {
    padding: 10px;
    text-align: center;
    color: #000;
    font-family: Manrope;
    font-size: clamp(13px,3vw,18px);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease; 
  }
  
  /* .nav-dropdown li:hover a {
    background-color: #e0e0e0;
  }
  
  .nav-dropdown li:active a {
    background-color: #c0c0c0;
 } */

  .nav-menu li:hover .nav-dropdown {
    display: block;
    opacity: 1;
    height: auto;
    transition: opacity 0.3s ease, height 0.3s ease; /* Add a transition for smooth effect */
  }
  
@media screen and (max-width:1060px) {
    .header{
        padding: 0px 20px;

    }
    .nav-menu{
        flex-direction: column;
        align-items: left;
        width:100%;
        height: 100vh;
        position:absolute;
        gap: 5px;
        top: 0%;
        left: -100%;
        background: rgba(0, 0, 0, 0.5); 
        z-index: 2;
        backdrop-filter: blur(20px);
        opacity: 1;
        transition: 0.3s  ;

    }
    .nav-menu.active{
        left:0;
        position: fixed; 
     

    }
    .nav-menu li.active{
        border-bottom:0px;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a {
        font-size: 18px;
        
    }
    .nav-dropdown {
        position: relative;
        background:none;
    }
    .nav-dropdown li a {
        color:#A6A6A6 ;
    }
.hamburger{
    display: initial;
    z-index: 3;
}

    }
/* .logo-image.svg{
   width: 101.383px;
   flex-shrink: 0;
} */
