.listbroder {
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: auto;
    padding: 81px 70px;
  }
  .aligm{
    display: inline;
  }
  @media screen and (max-width: 1040px) {
    /* Adjust styles for screens with a maximum width of 768 pixels */
    .listbroder {
      padding:41px 20px; /* Adjust the padding for smaller screens */
    }
  }
.listbutton {
    display: flex;
    justify-content: flex-end;
  }
 
.aligmlogoimg{
    display: flex;
justify-content: space-between;
align-items: center;
}
  
.listrow{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: auto;
}
.listcol{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
}
.listcol p{
    display: flex;
align-items: flex-end;
gap: 24px;
    color: #151515;
font-family: Manrope;
font-size:clamp( 14px,2vw,18px);
font-style: normal;
font-weight: 400;
line-height: 160%; /* 28.8px */
letter-spacing: 0.5px;
text-wrap: balance;
}
.listcol a{
    color: #1D1D1D;
text-align: justify;
font-family: Manrope;
font-size:clamp( 14px,2vw,18px);
font-style: normal;
font-weight: 400;
line-height: 160%; /* 28.8px */
letter-spacing: 0.36px;
text-decoration: none;
}
.listcol span{
    display: flex;
    gap: 24px;
    color: #1D1D1D;
    text-align: justify;
    font-family: Manrope;
    font-size:clamp( 14px,2vw,18px);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    letter-spacing: 0.36px;
    align-items: center;
}
.listcol2{
    display: flex;
flex-direction: column;
align-items: flex-end;
gap: 32px;

}