.home_brand_collab{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap:clamp(20px,3.5vw,70px);

}

.home_brand_collab span{
  color: #151515;
  font-family: Sora;
  font-size: clamp(35px,4vw,50px);
    font-style: normal;
  font-weight: 600;
  line-height: 40.4px;
  letter-spacing: 0.5px;
  gap:clamp(20px,3.5vw,70px);
  padding-bottom: 40px;

}
.marqgridcol1{
  color: #151515;
font-family: Manrope;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 160%; 
letter-spacing: 0.5px;
  padding-bottom: 50px;
  text-wrap: balance;
}

.marquee {
  position: relative;
  align-items: center; 
 justify-content: center;
  max-width: 100%;    
  height: 200px;
  overflow-x: hidden;
  border: 0 10px 0 10px solid #ffffff; 
  box-shadow: inset 80px 0 30px -8px rgb(255, 255, 255), inset -80px 0 30px -8px rgb(255, 254, 254);
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  padding-left: 30px;
  
  animation: marquee 10s linear infinite;
   z-index: -2;
}
.track img{
  padding-right: 80px;
 
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}