@import url('https://fonts.googleapis.com/css?family=Manrope:200,300,regular,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Sora:100,200,300,regular,500,600,700,800');

*{
  box-sizing: border-box;
  font-family: "outfit",sans-serif;
}
html, body {
  height: 100%;
}

body {
  margin:    0 auto;
  max-width: 100vw;
  background-color: #ffffff;
  overflow-x: hidden;
  text-size-adjust: 100%;
}
@media screen and (min-width: 1700px) {
  body {
    padding: 0 15vw 0 15vw;

  }
}
/* .btn-white{
  color: #450079;
  height: 60px;
  padding: 10px 30px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 56px;
background: #FFF;
display: inline-block;
transition: box-shadow 0.3s;
box-shadow: 0 0 0 0 transparent;
} */


.btn-white:hover {
  box-shadow: 10px 14px 0px  rgb(0, 0, 0);
}
.arrow-container:hover .arrow {
  transform: rotate( -45deg); 
  transition: transform 0.3s ease; 
}
.btn-purple {
  height: 60px;
  padding: 10px 30px 20px; /* Combined padding */
  justify-content: center; /* Only needed for flex containers */
  align-items: center; /* Only needed for flex containers */
  gap: 10px; /* Only needed for flex containers */
  color: #fff;
  background: #450079;
  border-radius: 56px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8; /* 60px / 18px */
  letter-spacing: 0.5px;
  text-align: center; /* Added for centering text */
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0 0 transparent;
  border: 2px solid transparent;
}

.btn-purple:hover {
  box-shadow: 10px 10px 0px rgb(166, 166, 166);
}

.btn-white {
  color: #450079;
  height: 60px;
  padding: 10px 30px;
  border-radius: 56px;
  background: #FFF;
  display: inline-block;
  transition: box-shadow 0.3s;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8; /* 60px / 18px */
  letter-spacing: 0.5px;
  text-align: center;
  border: 2px solid black;
}
.btn-white:hover {
  box-shadow: 10px 14px 0px rgb(0, 0, 0);
}

@media (max-width: 768px) {
  .btn-white,
  .btn-purple {
    height: 50px;
    width: auto ;

    padding: 8px 20px; 
    font-size: 14px; 
  }
}