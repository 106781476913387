

.wheader{
    display: flex;
    width: 100%;
    height: 100px;
    padding: 0px 70px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0; 
    background-color:#FFFFFf;

}

.wnav-menu{
    display: flex;
    gap: 59px;
    list-style-type: none;    
   background-color:#FFFFFF;
}
.wnav-menu span{
    display: flex;
align-items: center;
gap: 59px;
}
.wnav-menu li a  {
    color: #1D1D1D;
    font-family: Manrope;
    font-size: clamp(15px,2vw,18px);
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-decoration: none;
   


}
.wnav-menu li.active {
    
    font-family: Manrope;
    font-size: clamp(15px,2vw,18px);
   font-style: normal;
   font-weight: 400;
   line-height: 160%; 
   letter-spacing: 0.5px;  
   border-bottom: 3px solid #9200FF; 
   padding-bottom: 0px;
   
 }
  
.whamburger{
    display:none;
}
.wnav-dropdown {
    display: none;
  position: absolute;
  background: rgba(255, 255, 255, 1.60);
  /* backdrop-filter: blur(28px);       */
  z-index: 3;
  padding: 10px  ;
  list-style-type: none;
}
  .wnav-dropdown li {
    padding: 10px;
    text-align: center;
  }
  
  .wnav-dropdown li a {
    color: #000;
    font-family: Manrope;
    font-size: clamp(15px,2vw,18px);
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.5px;
    text-decoration: none;
    display: block; 
    transition: background-color 0.3s ease-in-out; 
  }
  
 
  .wnav-menu li:hover .wnav-dropdown {
    display: block;
  }
  .nav-options{
    display: flex;
    align-items: center;
    gap: 59px;
}


  @media screen and (max-width:1220px) {
    .wheader{
        padding: 0px 20px;

    }
    .wnav-menu{
        flex-direction: column;
        justify-content:none;
        align-items: left;
        width:100%;
        height: 100vh;
        position:absolute;
        top: 0%;
        left: -100%;
        background: rgba(255, 255, 255, 0.5); 
        z-index: 2;
        backdrop-filter: blur(40px);
        opacity: 1;
        transition: 0.3s  ;
        gap: 10px;

    }
    .nav-options{
        gap: 10px;
        align-items: left;


    }

    
    .wnav-menu.active{
        left:0;
        position: fixed;


    }
  
    #btnToHide {
        display: none;
      }
    .wnav-menu li{
        padding: 1rem 0;
    }       
    .wnav-menu li a {
        font-size:18px;
    }
    .wnav-dropdown {
        position: relative;
        background:none;
    }
.whamburger{
    display: initial;
    z-index: 3;
}
.nav-options{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.wnav-dropdown li {
    padding: 10px;
    text-align: left;
  }
    }
.wlogo-image{
   width: 101.383px;
   flex-shrink: 0;
}
