/* Default styles for larger screens */
form {
  width: 100%;
}

.formalignn {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
    margin-bottom: 90px;
    margin-top: 50px;
  }
  
  .formcontainer {
    display: flex;
    padding: 0px 70px;
    align-items: center;
  }
  
  .formrow {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  
  .formcol {
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 10px;
    background: #F8F8F8;
  }
  
  .formcontrol,
  .formcontrol2 {
    display: flex;
    width: 100%; 
    /* max-width: 500px;  */
    padding: 10px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #B0B0B0;
    color: #000;
    text-align: justify;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.36px;
  }
  
  @media (max-width: 768px) {
    .formcontrol,
    .formcontrol2 {
      flex-direction: column; 
      width: 100%; 
    }
  }
  
  .formcontrol2 {
    height: 127px;
    align-items: flex-start;
  }
  
  .formlabel {
    padding-bottom: 8px;
    color: #000;
    text-align: justify;
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.36px;
  }
  
  .formcol2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 70px;
  }
  
  @media (max-width: 768px) {
    .formcol2 {
      align-items: center;
      gap: 20px;
    }
  }
  
  .address {
    display: flex;
    align-items: flex-start;
    justify-content:space-between;
    flex-wrap: wrap;
    gap: 30px;
    
  }
  
  .address span {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    text-wrap: balance;
    gap: 24px;
  }
  .formcol2 iframe{
    width:50vw;
   
  }
  .success-message {
    background-color: #4caf50; /* Green */
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
}

 
  @media screen and (max-width: 768px) {
    .formalignn {
      gap: 20px;
      margin-bottom: 50px;
      margin-top: 30px;
    }
  
    .formcontainer {
      padding: 0px 30px;
    }
  
    .formrow {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .formcol,
    .formcontrol,
    .formcontrol2,
    .formlabel {
      width: 100%;
    }
    .formcol2 iframe{
      width:300px;
      height:auto;
    }
    .address {
      gap: 1rem ;
    }
    .address span{
      padding-left:  3rem; ;
    }
  }
    